// eslint-disable-next-line no-unused-vars
import * as React from "react";
import Layout from "./src/components/Layout";

export function wrapPageElement ({ element, props, ...others }) {
  return (
    <React.StrictMode>
      <Layout { ...props } > { element } </Layout>
    </React.StrictMode>
  );
};
